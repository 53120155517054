import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import moment from 'moment'

import { AuthContext } from '../authentication/auth-provider'
import { getCourseStats } from './query'
import Grade from './grade'

const StudentTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid black;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: white;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
  }
`
const StudentRow = styled.tr<{ inactive: boolean }>`
  opacity: ${({ inactive }) => (inactive ? 0.5 : 1)};
`
const StudentList: React.FC<any> = ({ students, avgFullPoints }) => {
  return (
    <StudentTable>
      <tr>
        <th>Name</th>
        <th>Surame</th>
        <th>ID</th>
        <th>Last Login</th>
        <th>Δ</th>
        <th>Points</th>
      </tr>
      {students.map((student) => {
        const { firstName, lastName, usosID, lastLogin, points, inactive } =
          student
        const fullPoints = points?.fullPoints
        const delta =
          typeof fullPoints == 'number' && !inactive
            ? Math.round((fullPoints - avgFullPoints) * 10) / 10
            : null
        return (
          <StudentRow inactive={inactive}>
            <td>{firstName}</td>
            <td>{lastName}</td>
            <td>{usosID}</td>
            <td>{lastLogin ? moment(lastLogin).fromNow() : 'never'}</td>
            <td>{delta}</td>
            <td>
              <Grade points={points} />
            </td>
          </StudentRow>
        )
      })}
    </StudentTable>
  )
}

const InstructorDashboard: React.FC<any> = ({ courseId }) => {
  const { user, pendling } = useContext(AuthContext)
  const [course, setCourse] = useState<any>(null)

  useEffect(() => {
    let mounted = true
    if (!pendling && !user) {
      navigate('/teaching')
    }
    if (user) {
      getCourseStats(user.token, courseId).then((course) => {
        const activeStudents: any[] = course.students.filter(
          ({ inactive }) => !inactive
        )
        const avgFullPoints =
          activeStudents.reduce((total, { points }) => {
            const fullPoints = points?.fullPoints
            if (fullPoints) {
              return fullPoints + total
            } else {
              return total
            }
          }, 0) / activeStudents.length
        if (mounted) setCourse({ ...course, avgFullPoints })
      })
    }
    return () => {
      mounted = false
    }
  }, [pendling, user])

  if (user && course) {
    const { name, students, avgFullPoints } = course
    return (
      <>
        <h2>{name}</h2>
        <StudentList students={students} avgFullPoints={avgFullPoints} />
      </>
    )
  }
  return <div>Loading, please wait.</div>
}

export default InstructorDashboard
